import React, { useEffect, useState } from 'react';
import { Route } from 'react-router';
import { Routes } from "react-router-dom";
import { ToastProvider } from 'react-toast-notifications';
import { IBreadcrumb } from './core/components/breadcrumbs/entities/Breadcrumb';
import { redirectLogin } from './core/services/Authentication';
import './css/controls.css';
import './css/reporting.css';
import './css/styles.css';
import MicrostrategyCubeDefinition from './misc/debugging/MicrostrategyCubeDefinition';
import MicrostrategyCubeFilters from './misc/debugging/MicrostrategyCubeFilters';
import PublicPage from './public/PublicPage';
import PageNotFound from './site/PageNotFound';
import Layout from './site/custom/Layout';
import { OECustomComponent } from './site/custom/components/OECustomComponent';
import { ICustomRouteProperties, defaultCustomRouteProperties } from './site/custom/entities/CustomRouteProperties';
import Dashboard from './site/custom/pages/Dashboard';
import { IRoute, getRouteList } from './site/entities/Route';
import { ISiteSettings, defaultSiteSettings, saveSiteSettings } from './site/entities/SiteSettings';
import { useGetUserRoutes } from './site/services/RouteService';
import { useGetSiteSettings } from './site/services/SiteSettingsService';
import { useGetCurrentUser } from './user/services/UserService';

const getComponent = (item: IRoute, routeProperties: ICustomRouteProperties, setRouteProperties: (i: ICustomRouteProperties) => void): any => {
    return (<OECustomComponent name={item.component} routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);
};

const App: React.FunctionComponent = () => {
    const { service, doRefresh } = useGetUserRoutes();
    const { service: userService } = useGetCurrentUser();

    const [routes, setRoutes] = useState<IRoute[]>([]);
    const { service: settingService } = useGetSiteSettings();
    const [settings, setSettings] = useState<ISiteSettings>(defaultSiteSettings);
    const [routeProperties, setRouteProperties] = useState<ICustomRouteProperties>(defaultCustomRouteProperties);

    const updatePageBreadcrumbs = (s: string, i: IBreadcrumb[] = []) => {
        updateRouteProperties({
            ...routeProperties, pageName: s,
            corePageName: routeProperties.corePageName || s,
            breadcrumbs: i, setPageBreadcrumbs: updatePageBreadcrumbs, setPageName: updatePageName
        });
    }

    const updatePageName = (i: string) => {
        updateRouteProperties({
            ...routeProperties, pageName: i,
            corePageName: routeProperties.corePageName || i,
            setPageBreadcrumbs: updatePageBreadcrumbs, setPageName: updatePageName
        });
    }

    const updateRouteProperties = (i: ICustomRouteProperties) => {
        setRouteProperties({ ...i, setPageBreadcrumbs: updatePageBreadcrumbs, setPageName: updatePageName });
    }

    useEffect(() => {
        if (!routeProperties.setPageBreadcrumbs) {
            setRouteProperties({ ...routeProperties, setPageBreadcrumbs: updatePageBreadcrumbs, setPageName: updatePageName });
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (settingService.result !== undefined) {
            setSettings(settingService.result);
        }
        // eslint-disable-next-line
    }, [settingService]);

    useEffect(() => {
        if (userService.result) {
            setRouteProperties({ ...routeProperties, user: userService.result });
        }
        // eslint-disable-next-line
    }, [userService]);

    useEffect(() => {
        // for public page we ignore not getting settings, all other pages should redirect to login if not authorized
        if (!window.location.pathname.includes('public-')) {
            if (typeof settings === 'number') {
                redirectLogin();
            } else {
                try {
                    saveSiteSettings(settings);
                    if (settings.maintenance && !settings.canDebug && !window.location.pathname.includes('maintenance')) {
                        window.location.href = "/maintenance";
                    }
                    else if (!settings.maintenance && window.location.pathname.includes('maintenance')) {
                        window.location.href = "/home";
                    }
                }
                catch {
                }

                if (settings.logoutRedirect !== '') {
                    doRefresh();
                }
                else {

                }
            }
        }
        // eslint-disable-next-line
    }, [settings]);

    useEffect(() => {
        if (service.result) {
            const r: IRoute[] = getRouteList(service.result).filter(q => q.isActive);
            setRoutes(r);
        }
        // eslint-disable-next-line
    }, [service]);

    const CheckAuthorization = () => {
        return (
            <ToastProvider>
                {window.location.pathname.includes('public-') && <PublicPage routeProperties={routeProperties} setRouteProperties={updateRouteProperties} />}
                {!window.location.pathname.includes('public-') && routes.length > 0 && (
                    <Layout routes={routes} routeProperties={routeProperties} setRouteProperties={updateRouteProperties} >
                        <Routes>
                            <Route path="/" element={<Dashboard routeProperties={routeProperties} setRouteProperties={updateRouteProperties} />} />
                            {routes.map((item: IRoute) => (
                                <Route
                                    key={item.id}
                                    path={item.path}
                                    element={getComponent(item, routeProperties, updateRouteProperties)}
                                />
                            ))}
                            <Route path="debug-cubedefinition" element={<MicrostrategyCubeDefinition />} />
                            <Route path="debug-cubefilters" element={<MicrostrategyCubeFilters />} />
                            <Route path="*" element={<PageNotFound routeProperties={routeProperties} setRouteProperties={updateRouteProperties} />} />
                        </Routes>
                    </Layout>
                )}
            </ToastProvider>
        );
    }


    return CheckAuthorization();
};
export default App;
